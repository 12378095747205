body{
    background-color: #115e59;
    font-family: 'Alegreya Sans';
    font-weight: 600;
    overflow: hidden;
}

h3, p {
    font-family: 'Alegreya Sans';
}

#page-loader {
    width: 100vw;
    height: 100lvh;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #115e59;
    z-index: 50;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    transition: clip-path 0.35s ease-in-out;
}

#page-loader.inactive {
    pointer-events: none;
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
}

/* SIDEBAR */

#sidebar{
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
}

#sidebar-collapsed-content{
    width: 60px;
    position: absolute;
    z-index: 5;
    pointer-events: all;
}

#sidebar-content{
    z-index: 10;
    opacity: 100;
}

#sidebar-toggle{
    position: absolute;
    pointer-events: all;
    cursor: pointer;
    top: 50%;
    right: -16px;
    border-radius: 0 4px 4px 0;
}

#auth-screen, #pin-screen, #profile-screen {
    display: none;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    padding-bottom: 50px;
}

#auth-screen.active, #pin-screen.active, #profile-screen.active {
    display: flex;
}

/* TABS */

#tab-container {
    position: absolute;
    pointer-events: all;
    cursor: pointer;
    top: 20%;
    right: -38px;
}

#tab-container .tab:nth-child(2) {
    transform: translateY(-3px);
}

.tab {
    position: relative;
    z-index: 20;
    padding: 0 5px;
    color: #83c3a7;
    background-color: #115e59;
    border-radius: 0 6px 6px 0;
}

.tab::before {
    position: relative;
    top: -16.5px;
    left: -5px;
    content: url('../svg/tab_top_inactive.svg');
    height: 8px;
    width: 8px;
    display: block;
    position: relative;
}

.tab::after {
    position: relative;
    top: -1.5px;
    left: -5px;
    content: url('../svg/tab_bottom_inactive.svg');
    height: 8px;
    width: 8px;
    display: block;
    position: relative;
}

.tab.active {
    z-index: 25;
    color: #115e59;
    background-color: #9cf1cb;
}

.tab.active::before {
    content: url('../svg/tab_top_active.svg');
}

.tab.active::after {
    content: url('../svg/tab_bottom_active.svg');
}

/* While collapsed */
#sidebar.collapsed, #sidebar.collapsed #sidebar-content{
    pointer-events: none;
}

#sidebar-toggle.collapsed svg{
    transform: rotate(180deg);
}

@media (max-width: 767.98px){
    #sidebar-content{
        transition: opacity 0.12s ease-in-out;
    }
    #sidebar.collapsed #sidebar-content{
        opacity: 0;
    }
    #map, #sidebar-content{
        height: calc(100dvh - 56px);
    }
    #sidebar.collapsed #sidebar-collapsed-content, #sidebar.opening #sidebar-collapsed-content{
        display: none;
    }
    body, #root{
        height: 100dvh;
        overflow: hidden;
    }
}

@media (min-width: 768px){
    #sidebar-content{
        transition: transform 0.22s ease-in-out;
    }
    #sidebar.collapsed #sidebar-content{
        transform: translateX(-110%);
    }
}

/* MAP */

.map-container{
    width: 100%;
    height: 100%;
}

#crosshair{
    z-index: 0;
    position: absolute;
    top: calc(50% - 18px);
    pointer-events: none;
    transition: right 0.51s ease;
}

.pin {
    background-image: url('../images/pin_small.png');
    background-size: cover;
    height: 35px;
    width: 25px;
    cursor: pointer;
    filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.44));
    transition: opacity ease-in-out .1s !important;
}

.pin.pin-hidden {
    opacity: 0 !important;
}

.pin.visited {
    background-image: url('../images/pin_small_visited.png');
}

.popup-tag {
    border-radius: 2px;
    padding: 0.2rem 0.3rem;
    margin: 0.1rem;
    letter-spacing: 0.03rem;

}

#pin-detail-btn {
    padding: 7px 3px;
}

#pin-detail-btn:hover {
    background-color: rgb(0 0 0 / 5%);
}

#pin-detail-btn:focus-visible {
    outline: none;
}

/* CONTROLS */

.controls{
    z-index: 10;
    position: absolute;
    top: 100px!important;
    right: 10px!important;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.12);
    background: #fff;
    border-radius: 4px;
    width: 29px;
    margin-top: 8px;
    cursor: pointer;
}

.control{
    height: 29px;
    border-bottom: 1px solid #ddd;
}

.control-top{
    border-radius: 4px 4px 0 0;
}

.control-bottom{
    border-radius: 0 0 4px 4px;
    border-bottom: none;
}
    
.control:hover{
    background: #ebebeb;
}

.control.toggle-active,.control.toggle-active:hover{
    background-color: rgb(212, 212, 212);
}

#markers-toggle {
    --fill: #3fb1ce;
    --stroke: #348fa7;
}

#markers-toggle.toggle-active {
    --fill: #82a6ae;
    --stroke: #5b7881;
}

/* DIAGONALS */

.diagonal {
    --auth-width: 100%;
    margin-top: 10px;
    position: relative;
    background-color: transparent;
}

.diagonal::before {
    background-color: #115e59;
    content: '';
    position: absolute;
    top: -50px;
    left: 0;
    width: var(--auth-width);
    height: calc(100% + 50px);
    clip-path: polygon(0% 40px, 100% 0, 100% 100%, 0 100%);
}

.diagonal-content {
    position: relative;
    z-index: 1;
    padding-bottom: 1.5rem;
}

.closing .diagonal::before, .opening .diagonal::before {
    width: 100%;
}

@media (max-height: 700px){
    .diagonal::before {
        top: -15px;
        height: calc(100% + 15px);
        clip-path: polygon(0% 15px, 100% 0, 100% 100%, 0 100%);
    }

    .diagonal-content {
        padding-bottom: 1rem;
    }
} 

/* MOBILE MENU TOGGLE */

#mobile-sidebar-toggle {
    background: transparent;
    border: 1px solid transparent;
    margin: 7px 0;
    padding: 10px
}
#mobile-sidebar-toggle:focus {
    outline-width: 0;
}
#mobile-sidebar-toggle [class*='bar-'] {
    background: #ffffff;
    display: block;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .2s ease all;
    transition: .2s ease all;
    border-radius: 2px;
    height: 2px;
    width: 33px;
    margin-bottom: 6px;
}
#mobile-sidebar-toggle .bar-bot {
    margin-bottom: 0;
}
.opened .bar-top {
    -webkit-transform: rotate(45deg)!important;
            transform: rotate(45deg)!important;
    -webkit-transform-origin: 15% 15%!important;
            transform-origin: 15% 15%!important;
}
.opened .bar-mid {
    opacity: 0!important;
}
.opened .bar-bot {
    -webkit-transform: rotate(45deg)!important;
            transform: rotate(-45deg)!important;
    -webkit-transform-origin: 15% 95%!important;
            transform-origin: 15% 95%!important;
}
#mobile-sidebar-toggle,
#mobile-sidebar-toggle:focus,
#mobile-sidebar-toggle:active,
#mobile-sidebar-toggle-icon:focus {
    border: none;
    box-shadow: none;
}

/* TAGS */

#tag-button:hover #tag-remove-button{
    color: rgb(255, 255, 255);
    transition: ease-in-out 0.12s;
}

/* LOGO */

.logo-image{
    filter: invert(5%)
}

.title-image{
    filter: invert(100%);
}

#sidebar-collapsed-content .logo-image{
    max-width: 47px;
    max-height: 47px;
}

#topbar .logo-image{
    max-width: 45px;
    max-height: 45px;
}

#logo-container{
    width: 200px;
    height: 200px;
}

#logo-pin, #logo-map{
    position: absolute;
    max-width: 200px;
}

#logo-map{
    z-index: 5;
}

#logo-pin{
    z-index: 10;
    animation: bob 3s infinite;
}

.ellipse::after{
    width: 20px;
    display: inline-block;
    content: '';
    animation: dots 1.8s infinite;
}

@keyframes bob {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-20px);
    }
}

@keyframes dots {
    0% { content: ''; }
    16% { content: '.'; }
    32% { content: '..'; }
    48% { content: '...'; }
    64% { content: '..'; }
    80% { content: '.'; }
    96% { content: ''; }
}

/* BUTTONS */

.btn-push {
    border-radius: 0.25rem;
    user-select: none;
    font-weight: 700;
    letter-spacing: 0.03rem;
}

.btn-push .inner {
    padding: 0.5rem 1.5rem;
    border-radius: 0.25rem;
    transform: translateY(-3px);
    transition: transform 0.3s, background-color 0.1s;
    will-change: transform;
    -webkit-tap-highlight-color: transparent;
    border-bottom: 2px solid transparent;
}

.btn-push.btn-small .inner {
    padding: 0.2rem;
    transform: translateY(-2px);
    border-bottom-width: 1px;
}

@media (hover: hover) {
    .btn-push:hover .inner {
        transform: translateY(0px);
    }
}

@media (hover: none) {
    .btn-push:active .inner {
        transform: translateY(0px);
    }
}

/* Teal */
.btn-teal {
    color: rgb(243 244 246);
    background-color: #115e59 !important;
}

.btn-teal .inner {
    background-color: rgb(15 118 110) !important;
    border-bottom-color: #115e59;
}

.btn-teal:active .inner {
    background-color: rgb(13, 107, 99) !important;
}

/* Red */
.btn-red {
    color: rgb(243 244 246);
    background-color: rgb(185 28 28) !important;
}

.btn-red .inner {
    background-color: rgb(220 38 38) !important;
    border-bottom-color: rgb(185 28 28);
}

.btn-red:active .inner {
    background-color: rgb(195, 26, 26) !important;
}

/* TAILWIND EXTRAS */

.w-22 {
    width: 5.5rem;
}
.w-26 {
    width: 6.5rem;
}
.w-30 {
    width: 7.5rem;
}
.w-34 {
    width: 8.5rem;
}
.w-38 {
    width: 9.5rem;
}
.w-42 {
    width: 10.5rem;
}

.text-lg, .text-2xl {
    letter-spacing: 0.04rem;
}
.text-md {
    font-size: 1rem;
    letter-spacing: 0.04rem;
}
.text-sm {
    letter-spacing: 0.03rem;
    line-height: 1.12em !important;
}

.font-medium {
    font-weight: 500 !important;
}

/* LOADERS */

.compass {
    position: relative;
    width: 150px;
    height: 150px;
}

.compass-outer {
    position: absolute;
    top: 0;
    left: 0;
}

.compass-inner {
    position: absolute;
    top: 45%;
    left: 35%;
    animation: compass-spin 2s infinite;
}

@keyframes compass-spin {
    from {transform:rotate(-138deg);}
    to {transform:rotate(223deg);}
}

/* CUSTOM CHECKBOX */

.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.checkmark {
    position: absolute;
    height: 25px;
    width: 25px;
    background-color: #fff;
    transform: translate(10px, -3px);
    border-radius: 2px;
    border: 1px solid rgb(15 118 110);
    transition: all ease-in-out 0.08s;
}
.checkbox-container:hover input ~ .checkmark {
    background-color: #e9f2e7;
}
.checkbox-container input:checked ~ .checkmark {
    background-color: rgb(15 118 110);
}
.checkmark:after {
    content: '';
    position: absolute;
    display: none;
}
.checkbox-container input:checked ~ .checkmark:after {
    display: block;
}
.checkbox-container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
